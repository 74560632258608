<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <b-row>
    <b-col>
      <!-- aby -->
    </b-col>
    <b-col>
      <b-card>
        <b-form @submit.prevent="handleSubmit()">
          <b-form-group label="Tiempo de subasta">
            <b-form-input v-model="settings.auction_timer" autocomplete="off" />
          </b-form-group>
          <b-form-group label="Comisión">
            <b-form-input v-model="settings.commission_percentage" autocomplete="off" />
          </b-form-group>
          <b-form-group label="Comisión única">
            <b-form-input v-model="settings.special_commission_percentage" autocomplete="off" />
          </b-form-group>
          <b-button type="submit">
            Guardar
          </b-button>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  computed: {
    ...mapGetters('settings', ['settings']),
  },
  created() {
    this.getSettings()
  },
  methods: {
    ...mapActions('settings', ['getSettings', 'editSettings']),
    handleSubmit() {
      this.editSettings(this.settings).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('settingsUpdated'),
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
